/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import routes_navbar from "../../../routes_navbar";
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import CenteredFooter from "../../../examples/Footers/CenteredFooter";

function Terms() {
    return (

        <>
            <DefaultNavbar
                routes={routes_navbar}
                action={{
                    type: "internal",
                    color: "info",
                }}
                sticky
            />
            <MKBox component="section" py={6} my={6}>
                <Container>
                    <MKBox pt={3} px={1} mt={3} mb={3}>
                        <MKTypography variant="h2" fontWeight="bold" align="center">
                            Terms of Service
                        </MKTypography>
                    </MKBox>

                    <MKBox px={1} mb={6}>
                        <MKTypography variant="h4" align="center">
                            Below is the Mindglimpse Disclaimer:
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            If you have any issues, concerns, or problems with this disclaimer, please exit our site by simply closing your internet browser. If you proceed, you agree with the content without objection.
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            This page receives updates as our site changes, and it is up to the visitor or user to check for updates.
                        </MKTypography>
                    </MKBox>

                    <MKBox px={1} mb={6}>
                        <MKTypography variant="h4" align="center">
                            General Disclaimer and Waiver of Liability:
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            The information published on this site is for informational and entertainment purposes.
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            Everything posted by Mindglimpse is as accurate and current as possible, given the data and images used for micro-expression analysis. There are no expressed or implicit warranties or representations regarding the content’s accuracy, completeness, or reliability.
                        </MKTypography>
                    </MKBox>

                    <MKBox px={1} mb={6}>
                        <MKTypography variant="h4" align="center">
                            Assumed Risk:
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            If you utilize the information on this site, you are doing so at your own risk. Under no circumstances is this not the case.
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            Mindglimpse is not liable for any damages, loss, financial or otherwise, that result from you relying on the site’s information. The only purpose of this website is to provide information. This information is for entertainment purposes and not for investing or gambling decisions.
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            As such, Mindglimpse is not responsible for any outcomes resulting from relying on this site’s information.
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            Any prediction or forecast is neither legal nor professional advice.
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            If you are gambling and utilizing information from this site, you are doing so completely and totally at your own risk.
                        </MKTypography>
                    </MKBox>

                    <MKBox px={1} mb={6}>
                        <MKTypography variant="h4" align="center">
                            Important:
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            Mindglimpse does not promote or endorse any form of wagering or gambling.
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            All content, materials, programs, services, and software on or made available by this site are for entertainment, educational, and informational purposes only.
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            If you feel that you may have a gambling problem or addiction, there are many outlets by which you can seek help.
                        </MKTypography>
                    </MKBox>

                    <MKBox px={1} mb={6}>
                        <MKTypography variant="h4" align="center">
                            Contact Information:
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="body1">
                            You can find contact information for us on our site. Please don’t hesitate to contact us if you have questions about any content contained on this website.
                        </MKTypography>
                        <br></br>
                    </MKBox>

                </Container>
            </MKBox>
            <MKBox pt={6} px={1} mt={12}>
                <CenteredFooter />
            </MKBox>
        </>
    );
}

export default Terms;

/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "./assets/theme";
import Presentation from "./layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "./routes";
import routes_navbar from "./routes_navbar";

import { ReactKeycloakProvider } from '@react-keycloak/web'

import keycloak from './keycloak'

const eventLogger = (event, error) => {
  // console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens) => {
  // console.log('onKeycloakTokens', tokens)
  sessionStorage.setItem('authentication', keycloak.token);

}

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    let i = 1 + 1;
  }, [eventLogger, tokenLogger]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ReactKeycloakProvider authClient={keycloak} keycloak={keycloak} onEvent={eventLogger} onTokens={tokenLogger}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ThemeProvider>
    </ReactKeycloakProvider>

  );
}

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useState } from 'react';

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";

// Routes
import routes_navbar from "../../../routes_navbar";

import '../../../assets/styles.css';

import {
    Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

import '../games.css';
import NFLJSON from '../NFL_2023/NFLTeams_2023.json';

import NFLGames from '../NFL_2023/NFLGames.json'
import NFLPlayers from '../NFL_2023/NFLPlayers.json'

import { useKeycloak } from "@react-keycloak/web";
import { ReactKeycloakProvider } from '@react-keycloak/web'

import { convertDate, getWinner } from "../../../utils/util";
import { getWriteup, predictScores, getGraphScores, getImageName, QBSelect } from "../NFL_2023/nflcommon_2023";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

var graphOptions = {
    scales: {
        r: {
            beginAtZero: true,
            ticks: {
                stepSize: 5,
            }
        }
    },
};

var players = NFLPlayers

var schedule = NFLGames[0]

var teamData = NFLJSON.teamData

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const gameId = urlParams.get('id')
const gameDateId = urlParams.get('date')

if (gameId && gameDateId != null) {
    schedule = NFLGames.find(item => ((item.description == gameId) && (item.gamedate == gameDateId)))
}

if (window.location.pathname.includes("NBA-")) {
    schedule = { 'title': 'Carolina Panthers at Atlanta Falcons', 'description': 'CAR @ ATL', 'sport': 'football', 'gamedate': '2022-10-30', 'gametime': '17:00:00', 'images': ['Carolina Panthers.jpg', 'Atlanta Falcons.jpg'], 'graph': { 'Baker Mayfield': { 'anger': '1.298', 'disgust': '0.01', 'fear': '0.471', 'happy': '31.753', 'sad': '9.219', 'surprise': '0.598', 'neutral': '56.652' }, 'PJ Walker': { 'anger': '0.026', 'disgust': '0.0', 'fear': '0.299', 'happy': '0.036', 'sad': '0.679', 'surprise': '0.024', 'neutral': '98.935' }, 'Marcus Mariota': { 'anger': '0.184', 'disgust': '0.118', 'fear': '0.072', 'happy': '70.429', 'sad': '1.086', 'surprise': '0.057', 'neutral': '28.054' }, 'Desmond Ridder': { 'anger': '0.557', 'disgust': '0.001', 'fear': '0.387', 'happy': '28.173', 'sad': '1.641', 'surprise': '0.238', 'neutral': '69.003' } }, 'playerPredictions': { 'Baker Mayfield': 21.540300990670502, 'PJ Walker': 20.683114224546, 'Marcus Mariota': 22.7105623762565, 'Desmond Ridder': 21.5685475580405 }, 'teams': ['Carolina Panthers', 'Atlanta Falcons'], 'writeup': 2, 'whatever': [['Baker Mayfield', 'PJ Walker'], ['Marcus Mariota', 'Desmond Ridder']] }
}

try {
    if (urlParams.get('date').includes("2022")) {
        schedule = { 'title': 'Carolina Panthers at Atlanta Falcons', 'description': 'CAR @ ATL', 'sport': 'football', 'gamedate': '2022-10-30', 'gametime': '17:00:00', 'images': ['Carolina Panthers.jpg', 'Atlanta Falcons.jpg'], 'graph': { 'Baker Mayfield': { 'anger': '1.298', 'disgust': '0.01', 'fear': '0.471', 'happy': '31.753', 'sad': '9.219', 'surprise': '0.598', 'neutral': '56.652' }, 'PJ Walker': { 'anger': '0.026', 'disgust': '0.0', 'fear': '0.299', 'happy': '0.036', 'sad': '0.679', 'surprise': '0.024', 'neutral': '98.935' }, 'Marcus Mariota': { 'anger': '0.184', 'disgust': '0.118', 'fear': '0.072', 'happy': '70.429', 'sad': '1.086', 'surprise': '0.057', 'neutral': '28.054' }, 'Desmond Ridder': { 'anger': '0.557', 'disgust': '0.001', 'fear': '0.387', 'happy': '28.173', 'sad': '1.641', 'surprise': '0.238', 'neutral': '69.003' } }, 'playerPredictions': { 'Baker Mayfield': 21.540300990670502, 'PJ Walker': 20.683114224546, 'Marcus Mariota': 22.7105623762565, 'Desmond Ridder': 21.5685475580405 }, 'teams': ['Carolina Panthers', 'Atlanta Falcons'], 'writeup': 2, 'whatever': [['Baker Mayfield', 'PJ Walker'], ['Marcus Mariota', 'Desmond Ridder']] }
    }
}
catch (error) {
}

var convertedDate

if (schedule.gametime.startsWith("00:") || schedule.gametime.startsWith("01:")) {
    convertedDate = convertDate(schedule.gamedate, true)
}
else {
    convertedDate = convertDate(schedule.gamedate, false)
}

let team1Name = schedule.teams[0]
let team2Name = schedule.teams[1]

let team1Players = []
let team2Players = []

let x = players[team1Name]
for (var key in x) {
    team1Players.push(key)
}

let y = players[team2Name]
for (var key in y) {
    team2Players.push(key)
}

let QBName1 = team1Players[0]
let QBName2 = team2Players[0]

var points = predictScores(QBName1, QBName2, team1Name, team2Name, teamData, schedule, players)
var graphData = getGraphScores(QBName1, QBName2, team1Name, team2Name, teamData, players)
var winner = getWinner(points, schedule)
var writeup = getWriteup(winner, points, teamData, schedule)

var totalPoints = points[0] + points[1]
var pointDifference = Math.abs(points[0] - points[1])

//var endCheck = false

const Team1QBSelect = QBSelect(team1Players);

const Team2QBSelect = QBSelect(team2Players);

function NFLPredictionsUpcoming() {

    const [QB1ImageState, setQB1ImageState] = useState(getImageName(0, QBName1, schedule));
    const [QB2ImageState, setQB2ImageState] = useState(getImageName(1, QBName2, schedule));

    const [totalPointState, setTotalPointState] = useState(totalPoints);
    const [pointDifferenceState, setPointDifferenceState] = useState(pointDifference);
    const [winningTeamState, setWinningTeamState] = useState(winner);
    const [writeupState, setWriteupState] = useState(writeup);
    const [graphState, setGraphState] = useState(graphData)

    function updateStates() {

        QBName1 = document.getElementById("QBSelect1").value
        QBName2 = document.getElementById("QBSelect2").value

        setQB1ImageState(getImageName(0, QBName1, schedule))
        setQB2ImageState(getImageName(1, QBName2, schedule))

        points = predictScores(QBName1, QBName2, team1Name, team2Name, teamData, schedule, players)
        graphData = getGraphScores(QBName1, QBName2, team1Name, team2Name, teamData, players)
        winner = getWinner(points, schedule)
        writeup = getWriteup(winner, points, teamData, schedule)

        totalPoints = points[0] + points[1]
        pointDifference = Math.abs(points[0] - points[1])

        setTotalPointState(totalPoints)
        setPointDifferenceState(pointDifference)
        setWinningTeamState(winner)
        setWriteupState(writeup)
        setGraphState(graphData)
    }

    return (
        <>
            <MKBox position="fixed" top="0.5rem" width="100%">
                <DefaultNavbar
                    routes={routes_navbar}
                    action={{
                        type: "internal",
                        color: "info",
                    }}
                />
            </MKBox>
            <MKBox pt={6} px={1} mt={12}>
                <MKTypography variant="h1" fontWeight="bold" align="center">
                    {schedule.title}
                </MKTypography>
                <MKTypography variant="h4" fontWeight="bold" align="center">
                    {convertedDate}
                </MKTypography>
            </MKBox>

            <MKBox pt={3} px={1} mt={3}>
                <MKTypography variant="h3" fontWeight="bold" align="center">
                    Choose Which Starting Quarterbacks to Analyze:
                </MKTypography>
            </MKBox>

            <MKBox pt={2} px={1} mt={3}>
                <table>
                    <tr>
                        <th>
                            <img src={"/players/NFL/2023/" + QB1ImageState + ".jpg"} alt={QBName1 + " (Picture Not Found)"} width="50%" />
                        </th>
                        <th>
                            <img src={"/players/NFL/2023/" + QB2ImageState + ".jpg"} alt={QBName2 + " (Picture Not Found)"} width="50%" />
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <select className="QBSelect" id="QBSelect1" onChange={updateStates}>
                                {Team1QBSelect}
                            </select>
                        </th>
                        <th>
                            <select className="QBSelect" id="QBSelect2" onChange={updateStates}>
                                {Team2QBSelect}
                            </select>
                        </th>
                    </tr>
                </table>
                <MKBox px={1} mb={6}>
                    <MKTypography variant="body1" align="center">
                        (Use the Dropdown Boxes to Choose Quarterbacks)
                    </MKTypography>
                </MKBox>
            </MKBox>

            <MKBox pt={3} px={1} mt={3}>
                <MKTypography variant="h2" fontWeight="bold" align="center">
                    Prediction Results:
                </MKTypography>
            </MKBox>

            <MKBox pt={2} px={1} mt={3}>
                <table>
                    <tr>
                        <th>Winning Team</th>
                        <th>Point Difference</th>
                        <th>Total Game Points</th>
                    </tr>
                    <tr>
                        <td>{winningTeamState}</td>
                        <td>{pointDifferenceState}</td>
                        <td>{totalPointState}</td>
                    </tr>
                </table>
            </MKBox>

            <MKBox pt={3} px={1} mt={3}>
                <MKTypography variant="h2" fontWeight="bold" align="center">
                    Quarterback Image Analysis Graph Results:
                </MKTypography>
            </MKBox>

            <Container>
                <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                    <Radar name="Chart" options={graphOptions} data={graphState} />
                </Grid>
            </Container>

            <MKBox pt={3} px={1} mt={3} mb={3}>
                <MKTypography variant="h2" fontWeight="bold" align="center">
                    Analysis Summary:
                </MKTypography>
            </MKBox>

            <MKBox px={1} mb={6}>
                <MKTypography variant="body1" align="center">
                    {writeupState}
                </MKTypography>
            </MKBox>
        </>
    );
}

export default NFLPredictionsUpcoming;
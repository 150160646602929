// Common functions used in the javascript code
var monthMap = {"January":31, "February":28, "March":31, "April":30, "May":31,"June":30,"July":31,"August":31,"September":30,"October":31,"November":30,"December":31}


export function convertDate(date_str, timeZoneConvert) {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let temp_date = date_str.split("-");
    if (timeZoneConvert) {
        var month = Number(temp_date[1]) - 1
        var day = parseInt(temp_date[2]) - 1
        var year = Number(temp_date[0]);
        if (day === 0) {
            month = Number(temp_date[1]) - 2
            if (month === Number(-1)) {
                month = months.length-1
                year = year - 1
            }
            day = monthMap[months[month]]
        }
        return months[month] + " " + day + ", " + year;
    }
    else {
        return months[Number(temp_date[1]) - 1] + " " + temp_date[2] + ", " + temp_date[0];
    }
}

export function convertTime(militaryTime) {
    var [hours, minutes, seconds] = militaryTime.split(':');
    if (hours == 0) {
        return `12:${minutes} AM`
    }
    else {
        return `${(hours > 12) ? hours % 12 : hours}:${minutes} ${(hours >= 12) ? 'PM' : 'AM'}`;
    }
}

export function toIsoTime(date) {
    return (new Date((date.getTime()) - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 10))
}

export function getWinner(points, output) {
    if (points[0] > points[1]) {
        return (output.teams[0])
    }
    else if (points[0] < points[1]) {
        return (output.teams[1])
    }
    else {
        return ("Toss Up")
    }
}
// @mui material components

// Sections components
import { useEffect, useState } from 'react';
import BaseLayout from "../../../layouts/sections/components/BaseLayout";
import MKBox from "../../../components/MKBox";

import { useNavigate } from 'react-router-dom';

import './pricing.css';

import { useKeycloak } from "@react-keycloak/web";

// Material Kit 2 React components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";
import MKButton from "../../../components/MKButton";

const ProductDisplay = ({ email, keycloak }) => (
  <>
    <MKBox component="section" py={6} my={6}>
      <Grid container item xs={12} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
        <Grid container spacing={3} justifyContent="center" padding={3} backgroundColor="#03399e">
          <Grid item xs={12} md={4} mx={1} mb={3} align="center">
            <section className="pricingWhite">
              <MKTypography variant="body1" fontWeight="bold" color="text" align="center">
                DEFAULT TIER
              </MKTypography>
              <MKTypography variant="h1" fontWeight="bold" align="center">
                Free
              </MKTypography>
              <hr></hr>
              <br></br>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Free Pick of the Week
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Archived Game Predictions
              </MKTypography>
              <MKTypography variant="body1" color="text" align="left" mb={2} mx={2}>
                <i class="fa fa-times"></i> Upcoming Game Predictions
              </MKTypography>
              <MKTypography variant="body1" color="text" align="left" mb={2} mx={2}>
                <i class="fa fa-times"></i> Point Differential Predictions
              </MKTypography>
              <MKTypography variant="body1" color="text" align="left" mb={2} mx={2}>
                <i class="fa fa-times"></i> Total Score Predictions
              </MKTypography>
              <MKTypography variant="body1" color="text" align="left" mb={2} mx={2}>
                <i class="fa fa-times"></i> Image Analysis Graphs
              </MKTypography>
              <br></br>
              <MKButton
                href="/games/Free-pick/NFL"
                variant={"gradient"}
                color={"info"}
                size="large"
                width="100%"
                align="center"
              >
                {"Check it Out"}
              </MKButton>
            </section>
          </Grid>
          <Grid item xs={12} md={4} mx={1} mb={3} align="center">
            <section className="pricingWhite">
              <MKTypography variant="body1" fontWeight="bold" color="text" align="center">
                PRO TIER
              </MKTypography>
              <MKTypography variant="h1" fontWeight="bold" align="center">
                $8/month
              </MKTypography>
              <hr></hr>
              <br></br>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Free Pick of the Week
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Archived Game Predictions
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Upcoming Game Predictions
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Point Differential Predictions
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Total Score Predictions
              </MKTypography>
              <MKTypography variant="body1" align="left" mb={2} mx={2}>
                <i class="fa fa-check"></i> Image Analysis Graphs
              </MKTypography>
              <br></br>
              <form id="checkoutForm" action="/api/v1/order/create-checkout-session" method="POST">
                <input type="hidden" name="lookup_key" value="prod_1" />
                <input type="hidden" name="email" value={email} />
              </form>
              <MKButton
                onClick={() => authenticateTest(keycloak)}
                variant={"gradient"}
                color={"info"}
                size="large"
                width="100%"
                align="center"
              >
                {"Subscribe"}
              </MKButton>
            </section>
          </Grid>
        </Grid>
      </Grid>
    </MKBox>
  </>
);

const ProductDisplayPaid = ({ email, keycloak }) => (
  <>
    <MKBox component="section" py={6} my={6}>
      <Grid container item xs={12} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
        <Grid container spacing={3} justifyContent="center" padding={3} backgroundColor="#03399e">
          <Grid item xs={12} md={4} mx={1} mb={3} align="center">
            <section className="pricingWhite">
              <MKTypography variant="body1" fontWeight="bold" color="text" align="center">
                PRO TIER (CURRENT TIER)
              </MKTypography>
              <MKTypography variant="h1" fontWeight="bold" align="center">
                $8/month
              </MKTypography>
              <hr></hr>
              <br></br>
              <form id="unsubscribeForm" action="/api/v1/order/create-portal-session" method="POST">
                <input type="hidden" name="email" value={email} />
              </form>
              <MKButton
                onClick={() => callUnsubscribe(keycloak)}
                variant={"gradient"}
                color={"info"}
                size="large"
                width="100%"
                align="center"
              >
                {"Manage Subscription"}
              </MKButton>
            </section>
          </Grid>
        </Grid>
      </Grid>
    </MKBox>
  </>
);

function callUnsubscribe(keycloak) {
  if (!keycloak.authenticated) {
    keycloak.login()
  }
  else {
    document.getElementById('unsubscribeForm').submit();
  }
}

function authenticateTest(keycloak) {
  if (!keycloak.authenticated) {
    keycloak.login()
  }
  else if (keycloak.hasRealmRole('paid')) {
    keycloak.accountManagement()
  }
  else {
    document.getElementById('checkoutForm').submit();
  }
}

function manageBilling(keycloak) {
  keycloak.accountManagement()
}

const SuccessDisplay = ({ sessionId, keycloak }) => {
  return (
    <>
      <section>
        <div className="product Box-root">
          <div className="description Box-root">
            <h2 className='billingHead'>Subscription to MindGlimpse Sports successful!</h2>
          </div>
        </div>
        <div className='divButton'>
          <MKButton
            onClick={() => manageBilling(keycloak)}
            variant={"gradient"}
            color={"info"}
            size="large"
          >
            {"Manage your billing information"}
          </MKButton>
        </div>
      </section>

    </>

  );
};

const Message = ({ message }) => (
  <section>
    <div className="product Box-root">
      <div className="description Box-root">
        <h2 className='billingHead'>Your purchase has been canceled.</h2>
      </div>
    </div>
    <div className='divButton'>
      <MKButton
        href="/"
        variant={"gradient"}
        color={"info"}
        size="large"
      >
        {"Return to Home Page"}
      </MKButton>
    </div>
  </section>
);

function Pricing() {
  const navigate = useNavigate();

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const { keycloak } = useKeycloak();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Your purchase has been canceled."
      );
    }
  }, [sessionId]);



  var paid = false
  if (keycloak.hasRealmRole("paid") || keycloak.hasRealmRole("admin")) {
    paid = true
  }

  if (!success && message === '') {
    if (paid) {
      return (
        <BaseLayout
          title="Pricing Plans"
        >
          <ProductDisplayPaid email={keycloak.idToken && keycloak.idTokenParsed.email} keycloak={keycloak} paid={paid} />
        </BaseLayout>
      );
    }
    else {
      return (
        <BaseLayout
          title="Pricing Plans"
        >
          <ProductDisplay email={keycloak.idToken && keycloak.idTokenParsed.email} keycloak={keycloak} paid={paid} />
        </BaseLayout>
      );
    }
  } else if (success && sessionId !== '') {
    return (
      <BaseLayout
        title="Checkout Confirmation"
      ><SuccessDisplay sessionId={sessionId} keycloak={keycloak} /></BaseLayout>
    );
  } else {
    return (
      <BaseLayout
        title="Checkout Canceled"
      >
        <Message message={message} />
      </BaseLayout>
    );
  }
}


export default Pricing;

// Convenience functions for NBA pages
export function getWriteup(winner, points, teamData, output) {
    let awayTeam = teamData[output.teams[0]].short
    let homeTeam = teamData[output.teams[1]].short
    let pointTotal = points[0] + points[1]

    if (winner == "Toss Up") {
        return ("The " + awayTeam + " are away this week, playing the "
            + homeTeam + " at home. Micro-expression analysis finds that the game will be very close, potentially ending in a tie. Micro-expression and quantitative analysis suggest the total predicted points in this game to be "
            + pointTotal + " point(s). In this case, Mindglimpse recommends taking the points based on the total over/under line coming out of Vegas. Also, pay close attention to the player's psychological profile graphs, as any slight edge could be the difference in breaking this predicted tie. Enjoy the game.")
    }

    else {

        let winningTeam = teamData[winner].short
        let pointDifference = Math.abs(points[0] - points[1])

        var writeup = ""
        switch (output.writeup) {
            case 0:
                writeup = "In this week's game, the " + awayTeam + " are the visiting team playing the " + homeTeam + " at home. Micro-expression analysis indicates the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression analysis and historical data find this game's total predicted points to be "
                    + pointTotal + ". In brief, Mindglimpse recommends that if one were to wager on this game, consider the point spread and total over/under line coming out of Vegas. Also, please pay close attention to the visual breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and their respective starting players. As always, you may wish to weigh the player psychological profiles more than the forecasted outcome; it is your choice. Enjoy the game."
                break;
            case 1:
                writeup = "The " + awayTeam + " are the visiting team, playing the " + homeTeam + " at home this week. Micro-expression analysis finds that the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression and quantitative research suggest that this game's total predicted points is "
                    + pointTotal + ". As such, Mindglimpse recommends considering the point spread and total over/under line coming out of Vegas if one were to bet on this game. Also, pay close attention to the breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and respective starting players. As always, you may want to weigh the quarterback's psychological profile more than the forecasted outcome; it is up to you. Have fun."
                break;
            case 2:
                writeup = "In the NBA this week, the " + awayTeam + " are the visiting team playing the " + homeTeam + " at home. Micro-expression analysis indicates the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression analysis and historical data find this game's total predicted points to be "
                    + pointTotal + ". In brief, Mindglimpse recommends that if one were to wager on this game, consider the point spread and total over/under line coming out of Vegas. Also, pay close attention to the graphic breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and corresponding starting players. As always, you may wish to weigh the player psychological profiles; it is your choice. Enjoy."
                break;
            case 3:
                writeup = "The " + awayTeam + " are away this week, playing the " + homeTeam + " at home. Micro-expression analysis finds that the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression and quantitative analysis suggest the total predicted points in this game to be "
                    + pointTotal + ". As such, Mindglimpse recommends considering if one were to bet on this game, reflect on the point spread and total over/under line coming out of Vegas. Also, pay close attention to the graphic breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and respective starting players. As always, you may want to weigh the quarterback's psychological profile more than the forecasted outcome; it is up to you."
                break;
            default:
                writeup = "The " + awayTeam + " are away this week, playing the " + homeTeam + " at home. Micro-expression analysis finds that the "
                    + winningTeam + " will win this game by " + pointDifference + " point(s). Micro-expression and quantitative analysis suggest the total predicted points in this game to be "
                    + pointTotal + ". As such, Mindglimpse recommends considering if one were to bet on this game, reflect on the point spread and total over/under line coming out of Vegas. Also, pay close attention to the graphic breakdown highlighting the micro-expression analysis assessments of the "
                    + awayTeam + " and " + homeTeam + " and respective starting players. As always, you may want to weigh the quarterback's psychological profile more than the forecasted outcome; it is up to you."
                break;
        }
        return (writeup);
    }
}

export function getGraphScoresEmpty(player1, player2, teamData, output) {
    let playerGraph1 = [0, 0, 0, 0, 0, 0, 0]
    let playerGraph2 = [0, 0, 0, 0, 0, 0, 0]

    let team1back = "rgba(" + teamData[output.teams[0]].priColor + ", .5)"
    let team1border = "rgba(" + teamData[output.teams[0]].priColor + ", 1)"
    let team2back = "rgba(" + teamData[output.teams[1]].priColor + ", .5)"
    let team2border = "rgba(" + teamData[output.teams[1]].priColor + ", 1)"

    graphData = {
        labels: ['Intensity', 'Caution', 'Stress', 'Confidence', 'Energy', 'Game Prep', 'Strategy'],
        datasets: [
            {
                label: player1 + " (" + teamData[output.teams[0]].short + ")",
                data: playerGraph1,
                backgroundColor: "rgba(128,128,128,.5)",
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
            },
            {
                label: player2 + " (" + teamData[output.teams[1]].short + ")",
                data: playerGraph2,
                backgroundColor: team2back,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
            },
        ],
    };
    return (graphData)
}

export function getGraphScores(player1, player2, team1Name, team2Name, players, teamData, output) {
    let i = 0
    let types = ["anger", "disgust", "fear", "happy", "sad", "surprise", "neutral"]
    let playerGraph1 = [0, 0, 0, 0, 0, 0, 0]
    let playerGraph2 = [0, 0, 0, 0, 0, 0, 0]

    let team1back = "rgba(" + teamData[output.teams[0]].priColor + ", .5)"
    let team1border = "rgba(" + teamData[output.teams[0]].priColor + ", 1)"
    let team2back = "rgba(" + teamData[output.teams[1]].priColor + ", .5)"
    let team2border = "rgba(" + teamData[output.teams[1]].priColor + ", 1)"

    for (i = 0; i < types.length; i++) {
        let score1 = (players[team1Name][player1][types[i]])
        let score2 = (players[team2Name][player2][types[i]])

        if (score1 > score2) {
            playerGraph1[i] = 10;
            playerGraph2[i] = 5;
        }
        else if (score2 > score1) {
            playerGraph1[i] = 5;
            playerGraph2[i] = 10;
        }
        else {
            playerGraph1[i] = 10;
            playerGraph2[i] = 10;
        }
    }

    let graphData = {
        labels: ['Intensity', 'Caution', 'Stress', 'Confidence', 'Energy', 'Game Prep', 'Strategy'],
        datasets: [
            {
                label: player1 + " (" + teamData[output.teams[0]].short + ")",
                data: playerGraph1,
                backgroundColor: "rgba(128,128,128,.5)",
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
            },
            {
                label: player2 + " (" + teamData[output.teams[1]].short + ")",
                data: playerGraph2,
                backgroundColor: team2back,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
            },
        ],
    };

    return (graphData)
}

export function predictScores(players, player1, player2, team1Name, team2Name, chem1, health1, chem2, health2) {

    let points1 = 0
    let points2 = 0

    try{
        let historical1 = players[team1Name][player1].baseScore

        chem1 = parseInt(chem1)
        health1 = parseInt(health1)

        points1 = Math.round(historical1 + (chem1 + health1))

    } catch (error) {

    }

    try {
        let historical2 = players[team2Name][player2].baseScore

        chem2 = parseInt(chem2)
        health2 = parseInt(health2)

        points2 = Math.round(historical2 + 5 + ((chem2 + health2)))
    } catch (error) {

    }

    let points = [points1, points2]

    return (points)
}

export function getImageName(player) {
    return (((player).replaceAll('.', "")).replace(/\s+/g, '-')).toLowerCase()
}

export function playerSelect(players) {
    return players.map(
        (info) => {
            return (
                <option value={info}>{info}</option>
            )
        }
    )
}
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";
import CenteredFooter from "../../../../examples/Footers/CenteredFooter";

// Routes
import routes from "../../../../routes";
import routes_navbar from "../../../../routes_navbar";

function BaseLayout({ title, children }) {
  return (
    <>
      <DefaultNavbar
        routes={routes_navbar}
        action={{
          type: "internal",
          color: "info",
        }}
        sticky
      />
      <br></br>
      <br></br>
      <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
        <Container sx={{ mt: 6 }}>
          <Grid container item xs={12} flexDirection="column" justifyContent="center" mx="auto">
            <MKTypography variant="h1" align="center" mb={1}>
              {title}
            </MKTypography>
            {children}
          </Grid>
        </Container>
        <MKBox mt="auto">
        <CenteredFooter />
      </MKBox>
      </MKBox>
    </>
  );
}

// Typechecking props for the BaseLayout
BaseLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";

// Routes
import routes_navbar from "../../../routes_navbar";

import "../../../assets/styles.css";

import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  Tooltip,
  ArcElement,
  BarElement,
} from "chart.js";
import { Radar, Pie, Bar } from "react-chartjs-2";

import "../games.css";

import NCAAJSON from "./baseScore.json";
import NCAATeamJSON from "./teamData.json";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend
);

let graphOptions = {
  scales: {
    r: {
      beginAtZero: true,
      ticks: {
        stepSize: 5,
      },
    },
  },
};

let pieOptions = {
  type: "pie",
};

let barOptions = {
  type: "bar",
  options: {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
};

let teamData = NCAAJSON.teamData;

let team1Players = [];
let team2Players = [];

let writeup =
  "In this week’s NCAA basketball game, <visiting> is the team playing <home>. \
Mindglimpse analysis has <winner> winning the game by <winAmount>. Further statistical investigation finds that the predicted total points in this game to be <total>. \
According to the deep analysis, the chart breakdown highlights the predicted differences in game outcomes according to field goals, assists, free throws, and three-point percentages in this game.";

function NCAAPredictions() {
  const [graphState, setGraphState] = useState({
    datasets: [],
  });
  const [pieState, setPieState] = useState({
    datasets: [],
  });
  const [barState, setBarState] = useState({
    datasets: [],
  });
  const [writeupState, setWriteupState] = useState("");

  //   =VLOOKUP(U4,I2:J363,2,FALSE)
  //   =VLOOKUP(S4,F2:G4,2,FALSE)
  //   =VLOOKUP(T4,A2:B6,2,FALSE)

  //   =VLOOKUP(U4,I2:P363,5,FALSE)
  function updateStates() {
    let teamSelectHome = document.getElementById("teamSelectHome").value;
    let teamSelectAway = document.getElementById("teamSelectAway").value;

    let team1Location = parseFloat(
      document.getElementById("team1LocationSelect").value
    );
    let team2Location = parseFloat(
      document.getElementById("team2LocationSelect").value
    );

    let teamHealthHome = parseFloat(
      document.getElementById("teamHealthHome").value
    );
    let teamHealthAway = parseFloat(
      document.getElementById("teamHealthAway").value
    );

    let teamHomeBase = NCAAJSON[teamSelectHome];
    let teamAwayBase = NCAAJSON[teamSelectAway];

    let teamHomeFinalScore = teamHomeBase + team1Location + teamHealthHome;
    let teamAwayFinalScore = teamAwayBase + team2Location + teamHealthAway;

    let homePointSpread = teamAwayFinalScore - teamHomeFinalScore;
    let awayPointSpread = teamHomeFinalScore - teamAwayFinalScore;

    let totalPoints = teamHomeFinalScore + teamAwayFinalScore;

    let homeTeamData = NCAATeamJSON[teamSelectHome];
    let awayTeamData = NCAATeamJSON[teamSelectAway];

    let homeFG = homeTeamData["FG"];
    let awayFG = awayTeamData["FG"];

    let home3P = homeTeamData["3P"];
    let away3P = awayTeamData["3P"];

    let homeFT = homeTeamData["FT"];
    let awayFT = awayTeamData["FT"];

    let homeAPG = homeTeamData["APG"];
    let awayAPG = awayTeamData["APG"];

    let homeComposite = (homeFG + home3P + homeFT) / 3;
    let awayComposite = (awayFG + away3P + awayFT) / 3;

    // calculate the chart data
    let homeFieldGoal = 5;
    let awayFieldGoal = 10;
    if (awayFG > homeFG) {
      homeFieldGoal = 10;
      awayFieldGoal = 5;
    }

    let home3Point = 5;
    let away3Point = 10;
    if (away3P > home3P) {
      home3Point = 10;
      away3Point = 5;
    }

    let homeFreeThrow = 5;
    let awayFreeThrow = 10;
    if (awayFT > homeFT) {
      homeFreeThrow = 10;
      awayFreeThrow = 5;
    }

    let homeAssists = 5;
    let awayAssists = 10;
    if (awayAPG > homeAPG) {
      homeAssists = 10;
      awayAssists = 5;
    }

    let homeTeamGraph = [homeAssists, homeFieldGoal, home3Point, homeFreeThrow];
    let awayTeamGraph = [awayAssists, awayFieldGoal, away3Point, awayFreeThrow];

    let graphData = {
      labels: ["Assists", "FieldGoal", "ThreePoints", "FreeThrow"],
      datasets: [
        {
          label: teamSelectHome,
          data: homeTeamGraph,
          backgroundColor: "rgba(128,128,128,.5)",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 1,
        },
        {
          label: teamSelectAway,
          data: awayTeamGraph,
          backgroundColor: "rgba(0,128,128,.5)",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 1,
        },
      ],
    };
    setGraphState(graphData);

    let pieData = {
      labels: [
        teamSelectHome + " " + teamHomeFinalScore + " points",
        teamSelectAway + " " + teamAwayFinalScore + " points",
      ],
      datasets: [
        {
          label: "Scores",
          data: [teamHomeFinalScore, teamAwayFinalScore],
          backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
          hoverOffset: 4,
        },
      ],
    };
    setPieState(pieData);

    let totalComposite = homeComposite + awayComposite;
    let homeIntensity = homeComposite / totalComposite;
    let awayIntensity = awayComposite / totalComposite;
    let barData = {
      labels: [teamSelectHome, teamSelectAway],
      datasets: [
        {
          label: "Intensity",
          data: [homeIntensity, awayIntensity],
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgb(255, 99, 132)"],
          borderWidth: 1,
        },
      ],
    };
    setBarState(barData);

    let winner = teamSelectHome;
    let winAmount = teamHomeFinalScore - teamAwayFinalScore;
    if (teamHomeFinalScore < teamAwayFinalScore) {
      winner = teamSelectAway;
      winAmount = teamAwayFinalScore - teamHomeFinalScore;
    }

    let tempWriteup = writeup
      .replace("<visiting>", teamSelectAway)
      .replace("<home>", teamSelectHome)
      .replace("<winner>", winner)
      .replace("<winAmount>", winAmount)
      .replace("<total>", totalPoints);
    setWriteupState(tempWriteup);
  }

  function teamSelect(team) {
    let teamNames = [];
    for (let key in team) {
      teamNames.push(key);
    }

    return teamNames.map((info) => {
      return <option value={info}>{info}</option>;
    });
  }
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes_navbar}
          action={{
            type: "internal",
            color: "info",
          }}
        />
      </MKBox>
      <MKBox pt={6} px={1} mt={12}>
        <MKTypography variant="h1" fontWeight="bold" align="center">
          NCAA
        </MKTypography>
      </MKBox>

      <MKBox pt={3} px={1} mt={3}>
        <MKTypography variant="h3" fontWeight="bold" align="center">
          Choose your teams:
        </MKTypography>
      </MKBox>

      <MKBox pt={2} px={1} mt={3}>
        <table>
          <tr>
            <th>
              <MKTypography variant="h3" fontWeight="bold" align="center">
                Team 1
              </MKTypography>
              {/* <img
                src={
                  "/players/NBA/" +
                  //   teamData[output.teams[0]].images +
                  "/" +
                  //   Player1ImageState +
                  ".png"
                }
                // alt={TeamName1 + " (Picture Not Found)"}
                width="50%"
              /> */}
            </th>
            <th>
              <MKTypography variant="h3" fontWeight="bold" align="center">
                Team 2
              </MKTypography>
              {/* <img
                src={
                  "/players/NBA/" +
                  //   teamData[output.teams[1]].images +
                  "/" +
                  //   Player2ImageState +
                  ".png"
                }
                // alt={TeamName2 + " (Picture Not Found)"}
                width="50%"
              /> */}
            </th>
          </tr>
          <tr>
            <th>
              <select
                className="PlayerSelect"
                id="teamSelectHome"
                onChange={updateStates}
              >
                {teamSelect(NCAAJSON)}
              </select>
            </th>
            <th>
              <select
                className="PlayerSelect"
                id="teamSelectAway"
                onChange={updateStates}
              >
                {teamSelect(NCAAJSON)}
              </select>
            </th>
          </tr>
          <tr>
            <th>
              <select
                className="Team1LocationSelect"
                id="team1LocationSelect"
                onChange={updateStates}
              >
                <option value="3.5" selected>
                  Home
                </option>
                <option value="0">Neutral</option>
                <option value="0">Away</option>
              </select>
            </th>
            <th>
              <select
                className="Team2LocationSelect"
                id="team2LocationSelect"
                onChange={updateStates}
              >
                <option value="3.5">Home</option>
                <option value="0">Neutral</option>
                <option value="0" selected>
                  Away
                </option>
              </select>
            </th>
          </tr>
        </table>
      </MKBox>

      <MKBox pt={3} px={1} mt={3}>
        <MKTypography variant="h3" fontWeight="bold" align="center">
          Team Health
        </MKTypography>
      </MKBox>

      <MKBox pt={2} px={1} mt={3}>
        <table>
          <tr>
            <th>
              <select
                className="ChemistrySelect"
                id="teamHealthHome"
                onChange={updateStates}
              >
                <option value="0" selected>
                  100%
                </option>
                <option value="-2.45">90%</option>
                <option value="-4.9">80%</option>
                <option value="-7.35">70%</option>
                <option value="-9.8">60%</option>
              </select>
            </th>
            <th>
              <select
                className="HealthSelect"
                id="teamHealthAway"
                onChange={updateStates}
              >
                <option value="0" selected>
                  100%
                </option>
                <option value="-2.45">90%</option>
                <option value="-4.9">80%</option>
                <option value="-7.35">70%</option>
                <option value="-9.8">60%</option>
              </select>
            </th>
          </tr>
        </table>
      </MKBox>

      <MKBox pt={3} px={1} mt={3}>
        <MKTypography variant="h2" fontWeight="bold" align="center">
          AI Prediction:
        </MKTypography>
      </MKBox>

      <MKBox pt={2} px={1} mt={3}>
        <table>
          <tr>
            <th>Winning Team</th>
            <th>Point Difference</th>
            <th>Total Game Points</th>
          </tr>
          <tr>
            {/* <td>{winningTeamState}</td>
            <td>{pointDifferenceState}</td>
            <td>{totalPointState}</td> */}
          </tr>
        </table>
      </MKBox>

      <MKBox pt={3} px={1} mt={3} mb={3}>
        <MKTypography variant="h2" fontWeight="bold" align="center">
          Analysis Summary:
        </MKTypography>
      </MKBox>

      <MKBox px={1} mb={6}>
        <MKTypography variant="body1" align="center">
          {writeupState}
        </MKTypography>
      </MKBox>

      <Container>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
          <Bar name="Bar" options={barOptions} data={barState} />
          <Radar name="Chart" options={graphOptions} data={graphState} />
          <Pie name="Pie" options={pieOptions} data={pieState} />
        </Grid>
      </Container>
    </>
  );
}

export default NCAAPredictions;

/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";

import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Gain an edge using mindglimpse"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            What Sets Us Apart
          </MKTypography>
          <MKTypography variant="body1" color="text">
            MindGlimpse uses an unprecedented approach when creating
            predictions.
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <MKBox position="relative">
              <MKTypography variant="body2" color="text" textAlign="center">
                Part of an Example Prediction:
              </MKTypography>
              <MKBox component="img" src="/example_result.webp" width="100%" />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="compare"
                  title="Groundbreaking Methods..."
                  description="MindGlimpse performs state of the art micro-expression image analysis on players to predict their performance."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="insert_chart"
                  title="...Backed Up By Tried and True Algorithms"
                  description="This is then merged with the more traditional prediction formulas that have been used in the past."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="public"
                  title="Best of Both Worlds"
                  description="By combining these two methods, every prediction is able to be verified using multiple techniques."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="The End Result"
                  description="This unique process allows us to provide you with the most accurate predictions possible."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
